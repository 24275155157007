<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="addUserModal"
      :title="type === 'add' ? `${$t('Add Merchant Agent')}`: `${$t('Edit Merchant Agent')}`"
      :buttons-hidden="true">
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required|alpha'" :label-placeholder="$t('First Name')" name="first name" v-model="data.first_name" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('first name')">{{ errors.first('first name') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required|alpha'" :label-placeholder="$t('Last Name')" name="last name" v-model="data.last_name" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('last name')">{{ errors.first('last name') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input :disabled="type === 'edit'" v-validate="'required|numeric|max:14'" name="national ID" :label-placeholder="$t('National ID')" v-model="data.national_id" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('national ID')">{{ errors.first('national ID') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" name="phone nmber" :label-placeholder="$t('Phone Number')" v-model="data.phone_number" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('phone nmber')">{{ errors.first('phone nmber') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required|numeric'" name="HR ID" :label-placeholder="$t('HR ID')" v-model="data.hr_id" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('HR ID')">{{ errors.first('HR ID') }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-1/2">
            <v-select v-on:input="primaryWarehouses ? loadZoneGroups(`${primaryWarehouses.code}`) : ''" class="mt-7 w-full" name="warehouse" v-model="primaryWarehouses" :placeholder="$t('Warehouse')" v-validate="'required'" label="name" :options="warehouses"/>
            <span class="text-danger text-sm" v-show="errors.has('warehouse')">{{ errors.first('warehouse') }}</span>
          </div>
          <div class="vx-col w-1/2"> 
            <v-select class="mt-7 w-full" name="secondary warehouses" v-model="secondary_warehouses" multiple v-validate="'required'" :placeholder="$t('Secondary Warehouses')" label="name" :options="warehouses"/>
            <span class="text-danger text-sm" v-show="errors.has('secondary warehouses')">{{ errors.first('secondary warehouses') }}</span>
          </div>
          <div class="vx-col w-1/2"> 
            <v-select ref="zoneGroups" v-validate.immediate="'required_if:merchants,null'" class="mt-10 w-full" name="zone group" v-model="zoneGroup" v-validate="'required'" :placeholder="$t('Zone Group')" label="name" :options="zoneGroups"/>
            <span class="text-danger text-sm" v-if="assignedMerchants !== null && errors.has('zone group')">{{ errors.first('zone group') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <v-select ref="merchants" v-validate.immediate="'required_if:zoneGroups,null'" @search="handleSearch" @keyup.enter.native="handleSearch" search multiple class="mt-10 w-full" name="merchants" v-model="assignedMerchants" :placeholder="$t('Merchants')" label="fullName" :options="merchants"/>
            <span class="text-danger text-sm" v-if="!zoneGroup">{{ errors.first('merchants') }}</span>
          </div>
        </div>
        <span class="text-danger text-sm" v-show="(type === 'add' && !zoneGroup == null && assignedMerchants !== null) || (type === 'edit' && zoneGroup && assignedMerchants.length > 0)">
          {{ $t('Merchant agents cannot be assigned to zone groups and merchants at the same time.') }}
        </span>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button :disabled="zoneGroup !== null && assignedMerchants.length > 0" v-if="type === 'add'" :class="zoneGroup !== null && assignedMerchants.length > 0 ? 'disable-btn' : 'active-btn'" @click="addUser" class="btn">{{ $t('Add') }}</button>
          <button :disabled="type === 'edit' && zoneGroup && assignedMerchants.length > 0" v-else @click="updateUser" :class="type === 'edit' && zoneGroup && assignedMerchants.length > 0 ? 'disable-btn' : 'active-btn'" class="btn">{{ $t('Save') }}</button>
        </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import i18nData from '../../../i18n/i18nData.js'
import common  from '@/assets/utils/common'

export default {
  props: ['warehouses', 'warehousesDic', 'addUserModal', 'type', 'data'],
  data () {
    return {
      assignedMerchants: [],
      merchants: [],
      searchVal: '',
      apiURL: 'api/v1/users/merchant-agents/',
      editMode: true,
      user: {
        first_name: '',
        last_name: '',
        national_id: '',
        phone_number: '',
        primary_warehouse: ''
      },
      primaryWarehouses: [],
      secondary_warehouses: [],
      zoneGroups: [],
      zoneGroup: null,
      zoneGroupDic: {}
    }
  },
  watch: {
    type () {
      if (this.type === 'add') {
        this.primaryWarehouses = []
        this.zoneGroups = []
        this.secondary_warehouses = []
        this.user = {
          first_name: '',
          last_name: '',
          national_id: '',
          phone_number: '',
          primary_warehouse: ''
        }
      }
    },
    data () {
      if (this.type === 'edit') {
        this.merchants = []
        this.assignedMerchants = []
        this.primaryWarehouses = []
        this.zoneGroup = null
        this.data.primary_warehouse = this.primaryWarehouses.push({
          id: this.data.primary_warehouse,
          name: this.warehousesDic[this.data.primary_warehouse].name,
          code: this.warehousesDic[this.data.primary_warehouse].code
        })
        this.secondary_warehouses = []
        if (this.data.secondary_warehouses) {
          this.data.secondary_warehouses.forEach(element => {
            this.secondary_warehouses.push({
              id: element,
              name: this.warehousesDic[element].name
            })
          })
        }
        if (this.data.assigned_merchants) {
          this.data.assigned_merchants.forEach(element => {
            this.assignedMerchants.push({
              id: element.id,
              fullName: `${element.name  } | (${  element.id})`
            })
          })
        }
      } else {
        this.zoneGroup = null
        this.primaryWarehouses = []
        this.secondary_warehouses = []
      } 
    },
    'primaryWarehouses' () {
      if (this.type === 'edit' && this.primaryWarehouses) {
        this.loadZoneGroups(this.primaryWarehouses[0] ? this.primaryWarehouses[0].code : this.primaryWarehouses.code)
      }
    }
  },
  methods: {
    loadZoneGroups (warehouseCode) {
      sendRequest(false, false, this, `api/v1/warehouses/${warehouseCode}/zone-groups/`, 'get', null, true,
        (response) => {
          this.zoneGroup = null
          this.zoneGroups = response.data
          this.zoneGroups.forEach(element => {
            this.zoneGroupDic[element.id] = element.name
          })
          if (this.type === 'edit' && this.data.zone_group) {
            this.data.zone_group = this.zoneGroup = {
              id: this.data.zone_group.id,
              name: this.data.zone_group.name
            }
          }
        }
      )
    },
    updateUser () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const secondary_warehouses = []
          this.secondary_warehouses.forEach(element => {
            secondary_warehouses.push(element.id)
          })
          const user = {
            first_name: this.data.first_name,
            last_name: this.data.last_name,
            national_id: this.data.national_id,
            phone_number: this.data.phone_number,
            primary_warehouse: this.primaryWarehouses.id,
            hr_id: Number(this.data.hr_id),
            assigned_merchants: [],
            zone_group: '',
            secondary_warehouses
          }
          if (this.zoneGroup) user.zone_group = this.zoneGroup.id
          const assignedMerchantsIds = []
          if (this.assignedMerchants.length > 0) {
            this.assignedMerchants.forEach(item => {
              assignedMerchantsIds.push(item.id)
            })
            user.assigned_merchants = assignedMerchantsIds
          }
          if (!this.isOnline) this.$emit('addUserModal', false)
          sendRequest(false, false, this, `${this.apiURL}${this.data.uid}/`, 'patch', user, true,
            () => {
              this.$vs.notify({
                color:'success',
                title: i18nData[this.$i18n.locale]['Success'],
                text: i18nData[this.$i18n.locale]['User'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.$emit('addUserModal', false)
              this.$emit('loadData')
            }
          )
        }
      })
    },
    addUser () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const secondary_warehouses = []
          this.secondary_warehouses.forEach(element => {
            secondary_warehouses.push(element.id)
          })
          const user = {
            first_name: this.data.first_name,
            last_name: this.data.last_name,
            national_id: this.data.national_id,
            phone_number: this.data.phone_number,
            primary_warehouse: this.primaryWarehouses.id,
            hr_id: Number(this.data.hr_id),
            secondary_warehouses
          }
          if (this.zoneGroup) user.zone_group = this.zoneGroup.id
          const assignedMerchantsIds = []
          if (this.assignedMerchants && this.assignedMerchants.length > 0) {
            this.assignedMerchants.forEach(item => {
              assignedMerchantsIds.push(item.id)
            })
            user.assigned_merchants = assignedMerchantsIds
          }
          if (!this.isOnline) this.$emit('addUserModal', false)
          sendRequest(false, false, this, this.apiURL, 'post', user, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['User'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.merchants = []
              this.$emit('addUserModal', false)
              this.$emit('loadData')
            }
          )
        }
      })
    },
    handleSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadMerchantsSearch)
    },
    loadMerchantsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadMerchants()
      }
    },
    loadMerchants () {
      this.searchInProgress = true
      const query = `?name=${this.searchVal}`
      sendRequest(false, false, this, `api/v1/merchants/${query}`, 'get', null, true,
        (response) => {
          this.merchants = response.data.results
          this.merchants.map(item => {
            item.fullName = `${item.name  } | (${  item.id})`
          })
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    closeModal () {
      this.merchants = []
      this.assignedMerchants = []
      this.$emit('addUserModal', false)
      this.$emit('loadData')
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss"
</style>