<template>
  <div id="merchant-page">
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('Merchant Agents')}}</h2>
      <span class="font-medium text-primary cursor-pointer">
        <vs-button
          @click="openAddUserModal('add')"
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          size="large">
        </vs-button>
      </span>
    </div>

    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      :search="$store.state.AppActiveUser.userRole !== 'head-of-fleet'"
      :data="merchantAgents"
      :tableLoader="tableLoader"
      :hideHeader="true"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Agent Name')}}</shipblu-th>
        <shipblu-th>{{$t('Phone Number')}}</shipblu-th>
        <shipblu-th>{{$t('HR ID')}}</shipblu-th>
        <shipblu-th>{{$t('Zone Group')}}</shipblu-th>
        <shipblu-th>{{$t('Main Warehouse')}}</shipblu-th>
        <shipblu-th>{{$t('Secondary Warehouses')}}</shipblu-th>
        <shipblu-th>{{$t('Assigned Merchants')}}</shipblu-th>
        <shipblu-th class="order-1 flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-11 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50">
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].first_name }} {{ data[indextr].last_name }}
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].phone_number">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Phone Number')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].phone_number }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].hr_id">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('HR ID')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].hr_id }}
            </p>
          </shipblu-td>
          
          <shipblu-td class="order-6" :data="data[indextr].zone_group">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Zone Group')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].zone_group ? data[indextr].zone_group.name : '' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].primary_warehouse">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Main Warehouse')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].primary_warehouse ? warehousesDic[data[indextr].primary_warehouse].name : '' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].secondary_warehouses">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Secondary Warehouse')}}</p>
            <p class="sm:text-base text-sm" v-for="warehouse in data[indextr].secondary_warehouses" :key="warehouse.index">
              {{ warehousesDic[warehouse].name }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].assigned_merchants">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Assigned Merchants')}}</p>
            <p class="sm:text-base text-sm" v-for="(merchant, index) in data[indextr].assigned_merchants" :key="index">
              {{ merchant.name }}<span v-if="index !== data[indextr].assigned_merchants.length - 1">,</span>
            </p>
          </shipblu-td>

          <shipblu-td class="order-2" style="vertical-align: middle;">
            <div v-if="data[indextr].cma_linked_account === null"  class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="openAddUserModal('edit', data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="resetPassword(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Change Password')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="deleteMerchantAgent(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <merchant-agent :zoneGroups="zoneGroups" userRole="Merchant Agent" :type="type" :warehousesDic="warehousesDic" :warehouses="warehouses" :data="merchantAgent" :addUserModal="addUserModal" @addUserModal="addUserModal = $event" @loadData="loadData"></merchant-agent>
    <reset-password userRole="merchant-agents" :resetPass="resetPass" :userData="userData" @loadData="loadData" @resetPass="resetPass = $event"></reset-password>
  </div>
</template>

<script>
import MerchantAgent from './components/AddMerchantAgent.vue'
import ResetPassword from './components/ResetPassword.vue'
import i18nData from '../../i18n/i18nData.js'
import common  from '@/assets/utils/common'
import {sendRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      agentsURL: 'api/v1/users/merchant-agents/',
      selected: [],
      merchantAgents: [],
      addUserModal: false,
      type: '',
      deleteData: {},
      merchantAgent: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      warehouses: [],
      zoneGroups: [],
      searchVal: '',
      warehousesDic: {},
      resetPass: false,
      userData: {},
      searchInProgress: false,
      tableLoader: false,
      searchedValue: ' '
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadData()
    },
    '$route.params.warehouseID' () {
      common.manageLoadWarehouses(this.formatWarehouses, this)
    },
    'this.merchantAgent.warehouse' () {
      this.loadZoneGroups()
    }
  },
  methods: {
    loadZoneGroups () {
      sendRequest(false, false, this, `api/v1/warehouses/${this.$route.params.warehouseID}/zone-groups/`, 'get', null, true,
        (response) => {
          this.zoneGroups = response.data
        }
      )
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadMerchantAgentsSearch)
    },
    loadMerchantAgentsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadData()
    },
    loadData () {
      this.searchInProgress = true
      this.tableLoader = true
      if (this.$store.state.AppActiveUser.userRole === 'head-of-fleet') {
        this.agentsURL = `api/v1/warehouse/${this.$route.params.warehouseID}/agents/merchant_agent/?offset=${this.offset}&limit=${this.maximumItems}`
      } else {
        this.agentsURL = `api/v1/users/merchant-agents/?offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}`
      }
      sendRequest(true, false, this, this.agentsURL, 'get', null, true,
        (response) => {
          this.selected = []
          this.merchantAgents = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    formatWarehouses (warehouses) {
      this.warehouses = warehouses.filter(item => !item.is_virtual)
      this.warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.id] = {
          name: warehouse.name,
          code: warehouse.code
        }
      })
      this.loadData()
    },
    openAddUserModal (type, data) {
      this.type = type
      if (type === 'add') {
        this.merchantAgent = Object.assign({}, {})
      } else {
        this.merchantAgent = data
      }
      this.addUserModal = true
    },
    deleteMerchantAgent (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/users/merchant-agents/${this.deleteData.uid}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Merchant Agent'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadData()
        }
      )
    },
    resetPassword (data) {
      this.resetPass = true
      this.userData = data
    }
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    common.manageLoadWarehouses(this.formatWarehouses, this)
  },
  components: {
    MerchantAgent,
    ResetPassword,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
